import { object, string } from 'yup';

export const schemaPqrForm = object().shape({
  phone_number: string()
    .trim()
    .matches(/^3\d{9}$/, 'El número de celular no es válido.')
    .min(10, 'mínimo 10 caracteres.')
    .max(10, 'máximo 10 caracteres.')
    .required('Digite un celular.'),
  email: string()
    .trim()
    .required('Digite un email.')
    .email('Digite un email válido'),
  request: string().trim().required('Digite un asunto.'),
  message: string().trim().required('Digite un mensaje.'),
});
