
/* eslint-disable camelcase */
import { defineAsyncComponent, defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import { AxiosError } from 'axios';

/** Utilities */
import { showErrors } from '@/utils/general-utils';
import {
  showSwalInfo,
  showSwalLoading,
  showSwalSuccess,
  showSwalError,
} from '@/utils/sweetalert2-utils';

/** Validations */
import { schemaPqrForm } from '@/validations/pqr-validation';

/** Interfaces */
import { UserLoginI } from '@/interfaces/auth.interface';
import { MessagePqrI, StatusPqrI } from '@/interfaces/pqr.interface';

export default defineComponent({
  name: 'ModalCustomerServiceChannels',
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    NewModal: defineAsyncComponent(
      () => import('@/components/shared/NewModal.vue')
    ),
    Form,
    Field,
  },
  emits: ['confirm', 'cancel', 'close-modal'],
  data() {
    return {
      showForm: false,
      schemaForm: schemaPqrForm,
      file: null,
      user: {
        documentType: '',
        noDocument: '',
        name: '',
      },
    } as {
      showForm: boolean;
      user: MessagePqrI;
      file: File | null;
    };
  },
  computed: {
    authUser(): UserLoginI {
      return this.$store.state.authUser || Object.create({});
    },

    fillForm(): MessagePqrI {
      const {
        document_type = '',
        name = '',
        identification = '',
      } = this.authUser;

      if (Object.keys(this.authUser).length !== 0) {
        this.setUser(name, identification, document_type);

        return this.user;
      }

      return this.user;
    },
  },
  methods: {
    setUser(name: string, identification: string, documentType: string) {
      this.user = {
        ...this.user,
        name,
        noDocument: identification,
        documentType,
      };
    },

    /** Validación para adjuntar un archivo. */
    uploadFile() {
      const inputFile = this.$refs.uploadFile as HTMLInputElement;
      const extensions =
        /(.bmp|.jpg|.jpeg|.gif|.png|.pdf|.doc|.docx|.xls|.xlsx|.ppt|.pptx|.zip|.mp3|.wav|.wma|.mid|.midi|.aac|.ac3|.flac)$/i;
      const size = 2097152;

      if (inputFile.files) {
        const file = inputFile.files[0];
        const invalidExtension = !extensions.exec(file?.name);
        const invalidSize = file.size > size;
        if (invalidExtension || invalidSize) {
          const message = invalidExtension
            ? 'El archivo adjunto no es permitido.'
            : 'El archivo adjunto excede las 2MB de tamaño';
          showSwalInfo(message);
          inputFile.value = '';
          this.file = null;
          return false;
        } else {
          this.file = file;
        }
      }
    },

    sendData(form: any) {
      showSwalLoading();
      const formData = new FormData();
      formData.append('message', form.message);
      formData.append('email', form.email);
      formData.append('issue', form.request);
      formData.append('phone_number', form.phone_number);

      if (this.file) {
        formData.append('file', this.file);
      }
      this.$store.dispatch('startTransactionApm', {
        apm: this.$apm,
        name: 'click - radicar PQR',
        type: 'custom',
        payload: form,
      });
      this.$store
        .dispatch('createPQR', formData)
        .then((res: StatusPqrI) => {
          this.$store.dispatch('addTransaction', res?.attributes);
          if (res.success) {
            showSwalSuccess(res.message).then(() => this.cancel());
          } else {
            showSwalError(res.message);
          }
        })
        .catch((error: AxiosError) =>
          showErrors(error, 'Se produjo un error al envíar la Solicitud.')
        )
        .finally(() => this.$store.dispatch('endTransactionApm'));
    },

    cancel() {
      this.showForm = false;
      this.$emit('cancel');
    },
  },
});
